/************ TABLE OF CONTENTS ***************
  1.Global CSS Settings
  1
**********************************************/

/***

====================================================================
	1.Global CSS Settings
====================================================================

***/

@font-face {
    font-family: "Playfair Display";
    src: local("Playfair Display ExtraBold"), local("PlayfairDisplay-ExtraBold"),
        url("/assets/fonts/PlayfairDisplay/PlayfairDisplay-ExtraBold.woff2")
            format("woff2");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Playfair Display";
    src: local("Playfair Display Bold"), local("PlayfairDisplay-Bold"),
        url("/assets/fonts/PlayfairDisplay/PlayfairDisplay-Bold.woff2")
            format("woff2");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Playfair Display";
    src: local("Playfair Display Black Italic"),
        local("PlayfairDisplay-BlackItalic"),
        url("/assets/fonts/PlayfairDisplay/PlayfairDisplay-BlackItalic.woff2")
            format("woff2");
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Playfair Display";
    src: local("Playfair Display Italic"), local("PlayfairDisplay-Italic"),
        url("/assets/fonts/PlayfairDisplay/PlayfairDisplay-Italic.woff2")
            format("woff2");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Playfair Display";
    src: local("Playfair Display ExtraBold Italic"),
        local("PlayfairDisplay-ExtraBoldItalic"),
        url("/assets/fonts/PlayfairDisplay/PlayfairDisplay-ExtraBoldItalic.woff2")
            format("woff2");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Playfair Display";
    src: local("Playfair Display Bold Italic"),
        local("PlayfairDisplay-BoldItalic"),
        url("/assets/fonts/PlayfairDisplay/PlayfairDisplay-BoldItalic.woff2")
            format("woff2");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Playfair Display";
    src: local("Playfair Display Black"), local("PlayfairDisplay-Black"),
        url("/assets/fonts/PlayfairDisplay/PlayfairDisplay-Black.woff2")
            format("woff2");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Playfair Display";
    src: local("Playfair Display SemiBold Italic"),
        local("PlayfairDisplay-SemiBoldItalic"),
        url("/assets/fonts/PlayfairDisplay/PlayfairDisplay-SemiBoldItalic.woff2")
            format("woff2");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Playfair Display";
    src: local("Playfair Display Regular"), local("PlayfairDisplay-Regular"),
        url("/assets/fonts/PlayfairDisplay/PlayfairDisplay-Regular.woff2")
            format("woff2");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Playfair Display";
    src: local("Playfair Display Medium"), local("PlayfairDisplay-Medium"),
        url("/assets/fonts/PlayfairDisplay/PlayfairDisplay-Medium.woff2")
            format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Playfair Display";
    src: local("Playfair Display Medium Italic"),
        local("PlayfairDisplay-MediumItalic"),
        url("/assets/fonts/PlayfairDisplay/PlayfairDisplay-MediumItalic.woff2")
            format("woff2");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Playfair Display";
    src: local("Playfair Display SemiBold"), local("PlayfairDisplay-SemiBold"),
        url("/assets/fonts/PlayfairDisplay/PlayfairDisplay-SemiBold.woff2")
            format("woff2");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: local("Open Sans Bold"), local("OpenSans-Bold"),
        url("/assets/fonts/OpenSans/OpenSans-Bold.woff2") format("woff2");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: local("Open Sans ExtraBold"), local("OpenSans-ExtraBold"),
        url("/assets/fonts/OpenSans/OpenSans-ExtraBold.woff2") format("woff2");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: local("Open Sans Bold Italic"), local("OpenSans-BoldItalic"),
        url("/assets/fonts/OpenSans/OpenSans-BoldItalic.woff2") format("woff2");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: local("Open Sans ExtraBold Italic"), local("OpenSans-ExtraBoldItalic"),
        url("/assets/fonts/OpenSans/OpenSans-ExtraBoldItalic.woff2")
            format("woff2");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: local("Open Sans Italic"), local("OpenSans-Italic"),
        url("/assets/fonts/OpenSans/OpenSans-Italic.woff2") format("woff2");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: local("Open Sans Light"), local("OpenSans-Light"),
        url("/assets/fonts/OpenSans/OpenSans-Light.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: local("Open Sans Medium Italic"), local("OpenSans-MediumItalic"),
        url("/assets/fonts/OpenSans/OpenSans-MediumItalic.woff2")
            format("woff2");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: local("Open Sans Medium"), local("OpenSans-Medium"),
        url("/assets/fonts/OpenSans/OpenSans-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: local("Open Sans Light Italic"), local("OpenSans-LightItalic"),
        url("/assets/fonts/OpenSans/OpenSans-LightItalic.woff2") format("woff2");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: local("Open Sans Regular"), local("OpenSans-Regular"),
        url("/assets/fonts/OpenSans/OpenSans-Regular.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: local("Open Sans SemiBold Italic"), local("OpenSans-SemiBoldItalic"),
        url("/assets/fonts/OpenSans/OpenSans-SemiBoldItalic.woff2")
            format("woff2");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
        url("/assets/fonts/OpenSans/OpenSans-SemiBold.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

html,
body {
    margin: 0;
    padding: 0;
    font-family: "Open Sans", sans-serif;
    background-color: #000;
}

:root {
    --primary-color: #000;
    --secondary-color: #f8e0de;
}

::-webkit-scrollbar {
    background-color: white;
    height: 0.35rem;
    width: 0.35rem;
}
::-webkit-scrollbar {
    height: 0.35rem;
    width: 0.35rem;
}
::-webkit-scrollbar-thumb {
    background-color: #9ca38f;
    border-radius: 10px;
}
::selection {
    background-color: #f4ccc9;
    color: #fff;
}

ul {
    list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Playfair Display", serif;
}

p,
div {
    font-family: "Open Sans", sans-serif;
}

.container {
    padding: 90px 10%;
}
